import React from 'react';

const Users = React.lazy(() => import('./users/index.users'));
const Projects = React.lazy(() => import('./projects/index.projects'));
const Furniture = React.lazy(() => import('./furniture/index.furniture'));
const Section = React.lazy(() => import('./main/index.main'));
const About = React.lazy(() => import('./view/about'));

const routes = [
    { path: '/', exact: true, name: 'Home', component: Projects },
    { path: '/users', exact: false, name: 'Users', component: Users },
    { path: '/projects', exact: false, name: 'Projects', component: Projects },
    { path: '/furniture', exact: false, name: 'Furniture', component: Furniture },
    { path: '/home', exact: false, name: 'Section', component: Section },
    { path: '/about', exact: false, name: 'About', component: About },
]

export default routes;