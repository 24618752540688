import React, { Component } from "react";
import { Link } from "react-router-dom";
import LayoutContext from "../context/layout.context";
export default class Layout extends Component {
  render() {
    return (
      <LayoutContext.Consumer>
        {(value) => (
          <nav id="sidebar" className={value.open ? "" : "active"}>
            <div className="sidebar-header">
              <h4>Portal Admin</h4>
            </div>
            <ul className="list-unstyled components">
            <li className="active">
                <a
                  href="#mainSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  Home
                </a>
                <ul className="collapse list-unstyled" id="mainSubmenu">
                  <li>
                    <Link to="/home">Section</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/">Projects</Link>
              </li>
              <li>
                <Link to="/furniture">Furniture</Link>
              </li>
              <li className="active">
                <a
                  href="#homeSubmenu"
                  data-toggle="collapse"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  Security
                </a>
                <ul className="collapse list-unstyled" id="homeSubmenu">
                  <li>
                    <Link to="/users">Users</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link onClick={value.onLogout} to="/">
                  Sign Out
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </LayoutContext.Consumer>
    );
  }
}
